import {Component, OnInit} from '@angular/core';
import {HeaderButton} from '../_utils/header-button.enum';
import {AdditionalInvoicePositionService} from '../_services/repositories/additional-invoice-position.service';
import {CurrentTeacherService} from '../_services/current-teacher.service';
import {HeaderService} from '../_services/header.service';
import {AdditionalInvoicePosition} from '../_models/additional-invoice-position';
import {LessonState} from '../_models/lesson-state.enum';
import {LessonService} from '../_services/repositories/lesson.service';
import {Filter} from '../_utils/filter/filter';

@Component({
	selector: 'app-my-additional-positions',
	templateUrl: './my-additional-positions.component.html',
	styleUrls: ['./my-additional-positions.component.scss']
})
export class MyAdditionalPositionsComponent implements OnInit
{
	
	public positions: Array<AdditionalInvoicePosition> = [];
	
	constructor(private headerService: HeaderService,
				private currentTeacherService: CurrentTeacherService,
				private lessonService: LessonService,
				private positionService: AdditionalInvoicePositionService)
	{
		this.headerService.pageTitle = 'Abrechnungspositionen';
		this.headerService.buttonAction = HeaderButton.MENU;
		
		
		this.loadPositions();
		
	}
	
	private loadPositions()
	{
		this.positions = [];
		
		let positionsFilter = [
			new Filter('teacher', this.currentTeacherService.teacher.id),
		];
		this.positionService.getAll(positionsFilter).subscribe((positions: Array<AdditionalInvoicePosition>) => {
			this.positions = positions;
		});
		
	}
	
	ngOnInit()
	{
	}
	
	getDateDisplayValue(date: Date)
	{
		return this.positionService.getDateDisplayValue(date);
	}
	
	getStateDisplayValue(state: string)
	{
		return this.lessonService.getStateDisplayValue(state);
	}
	
	
}
