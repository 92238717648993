import { Injectable } from '@angular/core';
import {RestService} from './rest-service';
import {AuthService} from '../auth.service';
import {HttpClient} from '@angular/common/http';
import {Student} from '../../_models/student';

@Injectable({
  providedIn: 'root'
})
export class StudentService extends RestService<Student> {
	
	constructor(httpClient: HttpClient, authService: AuthService)
	{
		super('students', httpClient, authService);
	}
}
