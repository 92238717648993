import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestService} from './rest-service';
import {User} from '../../_models/user';
import {AuthService} from '../auth.service';
import {Gender} from '../../_models/gender.enum';

@Injectable({
	providedIn: 'root'
})
export class UserService extends RestService<User>
{
	constructor(httpClient: HttpClient, authService: AuthService)
	{
		super('users', httpClient, authService);
	}
	
	public getGenderDisplayName(gender: string)
	{
		let sReturn = gender;
		
		switch (gender)
		{
			case 'o':
			case Gender.OTHER:
				sReturn = 'divers';
				break;
			case 'f':
			case Gender.FEMALE:
				sReturn = 'weiblich';
				break;
			case 'm':
			case Gender.MALE:
				sReturn = 'männlich';
				break;
			
		}
		
		return sReturn;
	}
	
}
