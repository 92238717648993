export class Model
{
  public id: number;
  
  constructor()
  {
  }
  
  // get id(): number
  // {
  //   return this._id;
  // }
  //
  // set id(value: number)
  // {
  //   this._id = value;
  // }
}
