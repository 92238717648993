import {Injectable} from '@angular/core';
import {RestService} from './rest-service';
import {Lesson} from '../../_models/lesson';
import {AuthService} from '../auth.service';
import {HttpClient} from '@angular/common/http';
import {LessonState} from '../../_models/lesson-state.enum';

@Injectable({
	providedIn: 'root'
})
export class LessonService extends RestService<Lesson>
{
	
	constructor(httpClient: HttpClient, authService: AuthService)
	{
		super('lessons', httpClient, authService);
	}
	
	public getLessonDateTimeString(start: number, end: number): string
	{
		if (!start || !end)
		{
			return '';
		}
		
		let startDate = new Date(start * 1000);
		let endDate = new Date(end * 1000);
		
		let day = ('0' + startDate.getDate()).substr(-2);
		let month = ('0' + (startDate.getMonth() + 1)).substr(-2);
		let year = startDate.getFullYear();
		
		let startHour = ('0' + startDate.getHours()).substr(-2);
		let startMinute = ('0' + startDate.getMinutes()).substr(-2);
		
		let endHour = ('0' + endDate.getHours()).substr(-2);
		let endMinute = ('0' + endDate.getMinutes()).substr(-2);
		
		return day + '.' + month + '.' + year + ' ' + startHour + ':' + startMinute + ' Uhr - ' + endHour + ':' + endMinute + ' Uhr';
		
	}
	
	public getStateDisplayValue(state: string): string
	{
		switch (state)
		{
			case LessonState.OPEN:
				return 'Offen';
				break;
			case LessonState.ACCOUNTING:
				return 'In Prüfung';
				break;
			case LessonState.ACCOUNTED:
				return 'Abgerechnet';
				break;
		}
	}
}