import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../_services/auth.service';
import {HeaderService} from '../_services/header.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit
{
	public loginForm: FormGroup;
	
	constructor(public authService: AuthService, public headerService: HeaderService)
	{
		this.headerService.pageTitle = 'LearnSolution TeacherApp';
	}
	
	ngOnInit()
	{
		this.loginForm = new FormGroup({
			username: new FormControl('', [Validators.required]),
			password: new FormControl('', [Validators.required]),
		});
	}
	
	public login()
	{
		let username = this.loginForm.get('username').value;
		let password = this.loginForm.get('password').value;
		this.authService.login(username, password, '/dashboard');
	}
}
