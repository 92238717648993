import {Injectable} from '@angular/core';
import {TeacherInvoice} from '../../_models/teacher-invoice';
import {RestService} from './rest-service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../auth.service';
import {Filter} from '../../_utils/filter/filter';
import {LessonState} from '../../_models/lesson-state.enum';
import {CurrentTeacherService} from '../current-teacher.service';

@Injectable({
	providedIn: 'root'
})
export class TeacherInvoiceService extends RestService<TeacherInvoice>
{
	constructor(httpClient: HttpClient, authService: AuthService, private currentTeacherService: CurrentTeacherService)
	{
		super('teacher-invoices', httpClient, authService);
	}
	
	public getTimespanDisplayValue(start: Date, end: Date)
	{
		if (!start.getDate)
		{
			start = new Date(start);
		}
		if(!end.getDate)
		{
			end = new Date(end);
		}
		
		let startDay = ('0' + start.getDate()).substr(-2);
		let startMonth = ('0' + (start.getMonth() + 1)).substr(-2);
		let startYear = (start.getFullYear());
		
		let endDay = ('0' + end.getDate()).substr(-2);
		let endMonth = ('0' + (end.getMonth() + 1)).substr(-2);
		let endYear = (end.getFullYear());
		
		return startDay + '.' + startMonth + '.' + startYear + ' bis ' + endDay + '.' + endMonth + '.' + endYear;
	}
	
	async getAllMock(invoiceFilter: Array<Filter>)
	{
		
		let invoices: Array<TeacherInvoice> = [];
		
		let invoice1 = new TeacherInvoice();
		invoice1.id = 1;
		invoice1.teacher = this.currentTeacherService.teacher;
		invoice1.status = LessonState.OPEN;
		invoice1.comment = 'TEST Comment';
		invoice1.phone = '123456789';
		invoice1.street = 'Musterstraße 1';
		invoice1.firstname = 'Max';
		invoice1.lastname = 'Musterman';
		invoice1.accountingDate = new Date();
		invoice1.startDate = new Date();
		invoice1.endDate = new Date();
		invoice1.email = 'test@test.de';
		invoice1.amount = 150.13;
		invoice1.city = 'Musterstadt';
		invoice1.zip = '73563';
		
		invoices.push(invoice1);
		invoices.push(invoice1);
		invoices.push(invoice1);
		
		return invoices;
	}
}