import {Model} from './model';
import {User} from './user';
import {Subject} from './subject';

export class Teacher extends Model{
	
	public user: User;
	public subjects: Array<Subject> = [];
	
	
	constructor()
	{
		super();
	}
}
