import { Component, OnInit } from '@angular/core';
import {HeaderService} from '../../_services/header.service';
import {HeaderButton} from '../../_utils/header-button.enum';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss']
})
export class ActionListComponent implements OnInit {
	
	constructor(private headerService: HeaderService)
	{
		this.headerService.pageTitle = 'Action List Example';
		this.headerService.buttonAction = HeaderButton.BACK;
	}

  ngOnInit() {
  }

}
