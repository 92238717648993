import {Model} from './model';
import {Teacher} from './teacher';

export class TeacherInvoice extends Model {

	public firstname: string;
	public lastname: string;
	public email: string;
	public street: string;
	public zip: string;
	public city: string;
	public phone: string;
	
	public teacher: Teacher;
	
	public startDate: Date;
	public endDate: Date;
	public accountingDate;
	public comment: string;
	public status: string;
	public amount: number;
	
	constructor()
	{
		super();
	}
	
}
