import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-not-implemented',
  templateUrl: './not-implemented.component.html',
  styleUrls: ['./not-implemented.component.sass']
})
export class NotImplementedComponent implements OnInit
{
  
  constructor()
  {
  }
  
  ngOnInit()
  {
  }
  
}
