import {Component, OnInit} from '@angular/core';
import {CurrentTeacherService} from '../_services/current-teacher.service';
import {StudentService} from '../_services/repositories/student.service';
import {HeaderService} from '../_services/header.service';
import {HeaderButton} from '../_utils/header-button.enum';
import {TeacherInvoice} from '../_models/teacher-invoice';
import {AdditionalInvoicePosition} from '../_models/additional-invoice-position';
import {TeacherInvoiceService} from '../_services/repositories/teacher-invoice.service';
import {Filter} from '../_utils/filter/filter';
import {LessonState} from '../_models/lesson-state.enum';
import {LessonService} from '../_services/repositories/lesson.service';

@Component({
	selector: 'app-my-teacher-invoices',
	templateUrl: './my-teacher-invoices.component.html',
	styleUrls: ['./my-teacher-invoices.component.scss']
})
export class MyTeacherInvoicesComponent implements OnInit
{
	
	public invoices: Array<TeacherInvoice> = [];
	
	constructor(private headerService: HeaderService,
				private currentTeacherService: CurrentTeacherService,
				private lessonService: LessonService,
				private teacherInvoiceService: TeacherInvoiceService)
	{
		this.headerService.pageTitle = 'Abrechnungen';
		this.headerService.buttonAction = HeaderButton.MENU;
		
		this.loadInvoices();
	}
	
	private loadInvoices()
	{
		this.invoices = [];
		
		let invoiceFilters = [
			new Filter('teacher', this.currentTeacherService.teacher.id)
		];
		this.teacherInvoiceService.getAll(invoiceFilters).subscribe((invoices: Array<TeacherInvoice>) =>
		{
			this.invoices = invoices;
		});
		
	}
	
	ngOnInit()
	{
	}
	
	getTimespanDisplayValue(start: Date, end: Date)
	{
		return this.teacherInvoiceService.getTimespanDisplayValue(start, end);
	}
	
	getStateDisplayValue(state: string)
	{
		return this.lessonService.getStateDisplayValue(state);
	}
	
}