import { Injectable } from '@angular/core';
import {RestService} from './rest-service';
import {Teacher} from '../../_models/teacher';
import {AuthService} from '../auth.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TeacherService extends RestService<Teacher> {
	
	constructor(httpClient: HttpClient, authService: AuthService)
	{
		super('teachers', httpClient, authService);
	}
}
