import {Model} from './model';
import {UserRole} from './user-role';
import {UserContact} from './user-contact';
import {UserBankAccount} from './user-bank-account';

export class User extends Model
{
	public username: string;
	public password: string;
	public email: string;
	public firstname: string;
	public lastname: string;
	public active: boolean;
	public roles: Array<UserRole>;
	public userContacts: Array<UserContact>;
	public userBankAccounts: Array<UserBankAccount>;
	public gender: string;
	public birthday: Date;

	
	
	// get username(): string
	// {
	//   return this.username;
	// }
	//
	// set username(value: string)
	// {
	//   this.username = value;
	// }
	//
	// get password(): string
	// {
	//   return this.password;
	// }
	//
	// set password(value: string)
	// {
	//   this.password = value;
	// }
	//
	// get email(): string
	// {
	//   return this.email;
	// }
	//
	// set email(value: string)
	// {
	//   this.email = value;
	// }
	//
	// get firstname(): string
	// {
	//   return this.firstname;
	// }
	//
	// set firstname(value: string)
	// {
	//   this.firstname = value;
	// }
	//
	// get lastname(): string
	// {
	//   return this.lastname;
	// }
	//
	// set lastname(value: string)
	// {
	//   this.lastname = value;
	// }
	//
	// get active(): boolean
	// {
	//   return this.active;
	// }
	//
	// set active(value: boolean)
	// {
	//   this.active = value;
	// }
	//
	// get roles(): Array<UserRole>
	// {
	//   return this.roles;
	// }
	//
	// set roles(value: Array<UserRole>)
	// {
	//   this.roles = value;
	// }
	//
	// get userContacts(): Array<UserContact>
	// {
	//   return this.userContacts;
	// }
	//
	// set userContacts(value: Array<UserContact>)
	// {
	//   this.userContacts = value;
	// }
	//
	// get userBankAccounts(): Array<UserBankAccount>
	// {
	//   return this.userBankAccounts;
	// }
	//
	// set userBankAccounts(value: Array<UserBankAccount>)
	// {
	//   this.userBankAccounts = value;
	// }
}
