import {Model} from './model';

export class SchoolType extends Model
{
	public key: string;
	public name: string;
	
	constructor()
	{
		super();
	}
}
