import {Component, OnInit} from '@angular/core';
import {Lesson} from '../_models/lesson';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HeaderService} from '../_services/header.service';
import {HeaderButton} from '../_utils/header-button.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {LessonService} from '../_services/repositories/lesson.service';
import {CurrentTeacherService} from '../_services/current-teacher.service';
import {MatSnackBar} from '@angular/material';
import {Subject} from '../_models/subject';
import {Student} from '../_models/student';
import {StudentService} from '../_services/repositories/student.service';
import {SubjectService} from '../_services/repositories/subject.service';
import {SchoolType} from '../_models/school-type';
import {SchoolTypeService} from '../_services/repositories/school-type.service';
import {LessonState} from '../_models/lesson-state.enum';
import {Filter} from '../_utils/filter/filter';

@Component({
	selector: 'app-new-lesson',
	templateUrl: './new-lesson.component.html',
	styleUrls: ['./new-lesson.component.scss']
})
export class NewLessonComponent implements OnInit
{
	public lesson: Lesson = new Lesson();
	public lessonForm: FormGroup;
	
	public readonly: boolean = false;
	
	public selectionStudents: Array<Student> = [];
	public selectionSubjects: Array<Subject> = [];
	public selectionSchoolClasses: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
	public selectionSchoolTypes: Array<SchoolType>;
	
	constructor(private headerService: HeaderService,
				private router: Router,
				private route: ActivatedRoute,
				private lessonService: LessonService,
				private studentService: StudentService,
				private subjectService: SubjectService,
				private schoolTypeService: SchoolTypeService,
				private currentTeacherService: CurrentTeacherService,
				private snackBar: MatSnackBar)
	{
		this.headerService.pageTitle = 'Nachhilfestunde';
		this.headerService.buttonAction = HeaderButton.BACK;
		
		this.readonly = true;
		
		this.lesson.schoolType = new SchoolType();
		this.lesson.student = new Student();
		this.lesson.subject = new Subject();
		
		this.lessonForm = new FormGroup({
			date: new FormControl('', [Validators.required]),
			start: new FormControl('', [Validators.required]),
			end: new FormControl('', [Validators.required]),
			student: new FormControl('', [Validators.required]),
			subject: new FormControl('', [Validators.required]),
			comment: new FormControl('', [Validators.required]),
			schoolClass: new FormControl('', [Validators.required]),
			schoolType: new FormControl('', [Validators.required]),
		});
		
		let filtersStudents = [
			new Filter('teachers', this.currentTeacherService.teacher.id)
		];
		this.studentService.getAll(filtersStudents).subscribe((students) =>
		{
			this.selectionStudents = students;
			this.schoolTypeService.getAll().subscribe((schoolTypes: Array<SchoolType>) =>
			{
				this.selectionSchoolTypes = schoolTypes;
				this.subjectService.getAll().subscribe((subjects: Array<Subject>) =>
				{
					this.selectionSubjects = subjects;
					
					let id = this.route.snapshot.params['id'];
					if (id != -1)
					{
						//Edit / View
						this.lessonService.get(id).subscribe((lesson: Lesson) =>
						{
							this.lesson = lesson;
							if (this.lesson.state == 'open' || this.lesson.state == '')
							{
								this.readonly = false;
							}
							
							this.setDurationFieldsOnForm();
						});
					}
					else
					{
						//New
						let sStart = (('0' + new Date().getHours()).substr(-2) + ':' + ('0' + new Date().getMinutes()).substr(-2)).slice(0, -1) + '0';
						let sEnd = (('0' + (new Date().getHours() + 1)).substr(-2) + ':' + ('0' + new Date().getMinutes()).substr(-2)).slice(0, -1) + '0';
						this.lessonForm.get('start').setValue(sStart);
						this.lessonForm.get('end').setValue(sEnd);
						this.lessonForm.get('date').setValue(new Date().toISOString());
						this.headerService.pageTitle = 'Neue Nachhilfestunde';
						this.lesson.teacher = currentTeacherService.teacher;
						this.readonly = false;
					}
					
				});
			});
		});
	}
	
	ngOnInit()
	{
	}
	
	public saveForm()
	{
		if (this.lessonForm.valid)
		{
			this.setDurationFieldsToLesson();
			if (this.lesson.id)
			{
				this.lessonService.update(this.lesson, this.lesson.id).subscribe((lesson: Lesson) =>
				{
					this.router.navigate(['/lessons']);
					this.snackBar.open('Erfolgreich gespeichert', 'OK');
				});
			}
			else
			{
				this.lesson.state = LessonState.OPEN;
				this.lessonService.create(this.lesson).subscribe((lesson: Lesson) =>
				{
					this.router.navigate(['/lessons']);
					this.snackBar.open('Erfolgreich gespeichert', 'OK');
				});
			}
			
		}
		else
		{
			const invalid = [];
			const controls = this.lessonForm.controls;
			for (const name in controls)
			{
				if (controls[name].invalid)
				{
					invalid.push('formErrors', name, controls[name].value);
				}
			}
			console.log(invalid);
			this.snackBar.open('Bitte behebe alle Fehler', 'OK');
		}
	}
	
	
	public delete()
	{
		if (confirm('Willst du die Nachhilfestunde wirklich löschen?'))
		{
			this.lessonService.delete(this.lesson.id).subscribe((success: boolean) =>
			{
				if (success)
				{
					this.router.navigate(['/lessons']);
					this.snackBar.open('Erfolgreich gelöscht', 'OK');
				}
				else
				{
					this.snackBar.open('Ups! Das hat nicht geklappt.', 'OK');
					console.log('Error while deleting');
				}
				
			});
		}
		
	}
	
	setDurationFieldsOnForm()
	{
		
		let dateStart = new Date(this.lesson.lessonStart * 1000);
		let dateEnd = new Date(this.lesson.lessonEnd * 1000);
		
		let sTimeStart = ('0' + dateStart.getHours()).substr(-2) + ':' + ('0' + dateStart.getMinutes()).substr(-2);
		let sTimeEnd = ('0' + dateEnd.getHours()).substr(-2) + ':' + ('0' + dateEnd.getMinutes()).substr(-2);
		
		this.lessonForm.get('date').setValue(dateStart.toISOString());
		this.lessonForm.get('start').setValue(sTimeStart);
		this.lessonForm.get('end').setValue(sTimeEnd);
		
	}
	
	public changeComment()
	{
		this.lesson.lessonComment = this.lessonForm.get('comment').value;
	}
	
	public selectionChangeStudent()
	{
		this.lesson.student = this.selectionStudents.find((student: Student) =>
		{
			return this.lessonForm.get('student').value == student.id;
		});
		
		this.lesson.schoolType = this.lesson.student.schoolType;
		this.lesson.schoolClass = this.lesson.student.schoolClass;
		if (this.lesson.student.subjects && this.lesson.student.subjects.length > 0)
		{
			this.lesson.subject = this.lesson.student.subjects[0]
		}
	}
	
	public selectionChangeSubject()
	{
		this.lesson.subject = this.selectionSubjects.find((subject: Subject) =>
		{
			return this.lessonForm.get('subject').value == subject.key;
		});
		
		let found = this.lesson.student.subjects.find((subject: Subject) =>
		{
			return subject.id == this.lesson.subject.id;
		});
		
		if (!found || !found.id)
		{
			this.snackBar.open('Diesem Schüler ist dieses Schulfach nicht zugewiesen, bist du sicher?', 'JA')
		}
	}
	
	public selectionChangeSchoolType()
	{
		this.lesson.schoolType = this.selectionSchoolTypes.find((schoolType: SchoolType) =>
		{
			return this.lessonForm.get('schoolType').value == schoolType.key;
		});
	}
	
	public selectionChangeSchoolClass()
	{
		this.lesson.schoolClass = this.lessonForm.get('schoolClass').value;
	}
	
	setDurationFieldsToLesson()
	{
		let dateStart = new Date(this.lessonForm.get('date').value);
		dateStart.setHours(this.lessonForm.get('start').value.split(':')[0]);
		dateStart.setMinutes(this.lessonForm.get('start').value.split(':')[1]);
		
		let dateEnd = new Date(this.lessonForm.get('date').value);
		dateEnd.setHours(this.lessonForm.get('end').value.split(':')[0]);
		dateEnd.setMinutes(this.lessonForm.get('end').value.split(':')[1]);
		
		this.lesson.lessonStart = dateStart.getTime() / 1000;
		this.lesson.lessonEnd = dateEnd.getTime() / 1000;
		
	}
	
	public getTimeSelection()
	{
		let selection = [];
		for (let i = 0, l = 24; i < l; i++)
		{
			for (let j = 0, k = 60; j < k; j = j + 5)
			{
				
				let sHours = '0' + i;
				let sMinutes = '0' + j;
				
				selection.push(sHours.substr(-2) + ':' + sMinutes.substr(-2));
			}
		}
		return selection;
	}
	
	getStateDisplayValue(state: string)
	{
		return this.lessonService.getStateDisplayValue(state);
	}
}
