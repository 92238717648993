import {Model} from './model';

export class Subject extends Model {
	
	public key: string;
	public name: string;
	
	
	constructor()
	{
		super();
	}
}
