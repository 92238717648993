import {Injectable} from '@angular/core';
import {HeaderButton} from '../_utils/header-button.enum';

@Injectable({
  providedIn: 'root'
})
export class HeaderService
{
  private _pageTitle: string = 'LearnSolution';
  private _buttonAction: HeaderButton = HeaderButton.MENU;
  
  get pageTitle(): string
  {
    return this._pageTitle;
  }
  
  set pageTitle(value: string)
  {
    this._pageTitle = value;
  }
  
  get buttonAction(): HeaderButton
  {
    return this._buttonAction;
  }
  
  set buttonAction(value: HeaderButton)
  {
    this._buttonAction = value;
  }
  
  constructor()
  {
  }
}
