import {BrowserModule} from '@angular/platform-browser';
import {ChangeDetectorRef, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import {NotImplementedComponent} from './not-implemented/not-implemented.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {TestComponentComponent} from './test-component/test-component.component';
import {LoginComponent} from './login/login.component';
import {ReactiveFormsModule} from '@angular/forms';
import {RecordReadonlyComponent} from './test-component/record-readonly/record-readonly.component';
import {RecordEditComponent} from './test-component/record-edit/record-edit.component';
import {HttpClientModule} from '@angular/common/http';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {SelectionListComponent} from './test-component/selection-list/selection-list.component';
import {ActionListComponent} from './test-component/action-list/action-list.component';
import {OptionListComponent} from './test-component/option-list/option-list.component';
import {MyLessonsComponent} from './my-lessons/my-lessons.component';
import {NoAccessComponent} from './no-access/no-access.component';
import {MyStudentsComponent} from './my-students/my-students.component';
import {MyStudentComponent} from './my-student/my-student.component';
import {NewLessonComponent} from './new-lesson/new-lesson.component';
import {MyTeacherInvoicesComponent} from './my-teacher-invoices/my-teacher-invoices.component';
import {MyTeacherInvoiceComponent} from './my-teacher-invoice/my-teacher-invoice.component';
import {MyAccountComponent} from './my-account/my-account.component';
import {MyAdditionalPositionsComponent} from './my-additional-positions/my-additional-positions.component';
import {MyAdditionalPositionComponent} from './my-additional-position/my-additional-position.component';
import {AuthService} from './_services/auth.service';
import {CurrentTeacherService} from './_services/current-teacher.service';
import {HeaderService} from './_services/header.service';
import {AdditionalInvoicePosition} from './_models/additional-invoice-position';
import {LessonService} from './_services/repositories/lesson.service';
import {SchoolTypeService} from './_services/repositories/school-type.service';
import {StudentService} from './_services/repositories/student.service';
import {SubjectService} from './_services/repositories/subject.service';
import {TeacherService} from './_services/repositories/teacher.service';
import {TeacherInvoiceService} from './_services/repositories/teacher-invoice.service';
import {UserService} from './_services/repositories/user.service';
import {Gender} from './_models/gender.enum';
import {LessonState} from './_models/lesson-state.enum';
import {ResponsiveService} from './_services/responsive.service';
import {RestService} from './_services/repositories/rest-service';
import {MatSnackBar} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {MediaMatcher} from '@angular/cdk/layout';
import {DefaultGuardService} from './_guards/default-guard.service';

@NgModule({
	declarations: [
		AppComponent,
		NotImplementedComponent,
		NotFoundComponent,
		TestComponentComponent,
		LoginComponent,
		RecordReadonlyComponent,
		RecordEditComponent,
		DashboardComponent,
		SelectionListComponent,
		ActionListComponent,
		OptionListComponent,
		MyLessonsComponent,
		NoAccessComponent,
		MyStudentsComponent,
		MyStudentComponent,
		NewLessonComponent,
		MyTeacherInvoicesComponent,
		MyTeacherInvoiceComponent,
		MyAccountComponent,
		MyAdditionalPositionsComponent,
		MyAdditionalPositionComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		MaterialModule,
		ReactiveFormsModule,
		ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
	],
	providers: [
		AuthService,
		CurrentTeacherService,
		HeaderService,
		AdditionalInvoicePosition,
		LessonService,
		SchoolTypeService,
		StudentService,
		SubjectService,
		TeacherService,
		TeacherInvoiceService,
		UserService,
		ResponsiveService,
		MatSnackBar,
		DefaultGuardService,
	],
	bootstrap: [AppComponent]
})
export class AppModule
{
}
