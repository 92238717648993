import {Component, OnInit} from '@angular/core';
import {HeaderButton} from '../../_utils/header-button.enum';
import {HeaderService} from '../../_services/header.service';

@Component({
	selector: 'app-record-readonly',
	templateUrl: './record-readonly.component.html',
	styleUrls: ['./record-readonly.component.scss']
})
export class RecordReadonlyComponent implements OnInit
{
	
	constructor(private headerService: HeaderService)
	{
		this.headerService.pageTitle = 'Nachhilfestunde hinzufügen';
		this.headerService.buttonAction = HeaderButton.BACK;
	}
	
	ngOnInit()
	{
	}
	
}
