import {Component, OnInit} from '@angular/core';
import {HeaderButton} from '../_utils/header-button.enum';
import {HeaderService} from '../_services/header.service';
import {Lesson} from '../_models/lesson';
import {LessonService} from '../_services/repositories/lesson.service';
import {Filter} from '../_utils/filter/filter';
import {LessonState} from '../_models/lesson-state.enum';
import {TeacherInvoice} from '../_models/teacher-invoice';
import {TeacherInvoiceService} from '../_services/repositories/teacher-invoice.service';
import {ActivatedRoute} from '@angular/router';
import {AdditionalInvoicePosition} from '../_models/additional-invoice-position';
import {AdditionalInvoicePositionService} from '../_services/repositories/additional-invoice-position.service';

@Component({
	selector: 'app-my-teacher-invoice',
	templateUrl: './my-teacher-invoice.component.html',
	styleUrls: ['./my-teacher-invoice.component.scss']
})
export class MyTeacherInvoiceComponent implements OnInit
{
	
	public invoice: TeacherInvoice;
	public lessons: Array<Lesson>;
	public positions: Array<AdditionalInvoicePosition>;
	
	constructor(private headerService: HeaderService,
				private invoiceService: TeacherInvoiceService,
				private additionalPositionService: AdditionalInvoicePositionService,
				private route: ActivatedRoute,
				private lessonService: LessonService)
	{
		this.headerService.pageTitle = 'Abrechnung';
		this.headerService.buttonAction = HeaderButton.BACK;
		
		let id = this.route.snapshot.params['id'];
		
		
		
		
		this.invoiceService.get(id).subscribe((invoice: TeacherInvoice) =>
		{
			this.invoice = invoice;
			let filtersLessons = [
				new Filter('teacherInvoice', id)
			];
			
			this.lessonService.getAll(filtersLessons).subscribe((lessons: Array<Lesson>) =>
			{
				this.lessons = lessons;
			});
			
			let filtersPositions = [
				new Filter('teacherInvoice', id)
			];
			
			this.additionalPositionService.getAll(filtersPositions).subscribe((positions: Array<AdditionalInvoicePosition>) =>
			{
				this.positions = positions;
			});
			
		});
		
		
	}
	
	ngOnInit()
	{
	}
	
	getLessonDateTimeString(start: number, end: number)
	{
		return this.lessonService.getLessonDateTimeString(start, end);
	}
	
	getStateDisplayValue(state: string)
	{
		return this.lessonService.getStateDisplayValue(state);
	}
	
	getDateDisplayValue(date: Date)
	{
		return this.additionalPositionService.getDateDisplayValue(date);
	}
	
	getTimespanDisplayValue(start: Date, end: Date) {
		return this.invoiceService.getTimespanDisplayValue(start, end);
	}
	
}
