import {IAuthTokenDecoded} from '../_interfaces/iauth-token-decoded';

export class ServiceUtil
{
  /**
   * base64 decoding for JWT strings
   *
   * @see https://winsmarts.com/parse-jwt-tokens-using-typescript-9e9c1e186f3e
   * @param str
   * @return string
   */
  private static urlBase64Decode(str: string): string
  {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4)
    {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        // tslint:disable-next-line:no-string-throw
        throw 'Illegal base64url string!';
    }
    return decodeURIComponent((<any> window).escape(window.atob(output)));
  }
  
  /**
   * function to decode JWT strings
   *
   * @param token
   * @return IAuthTokenDecoded
   * @see https://winsmarts.com/parse-jwt-tokens-using-typescript-9e9c1e186f3e
   */
  public static decodeJwt(token: string): IAuthTokenDecoded
  {
    if (token === null || token === '')
    {
      throw new Error('Empty token has been provided');
    }
    const parts = token.split('.');
    if (parts.length !== 3)
    {
      
      throw new Error('JWT must have 3 parts');
    }
    const decoded = ServiceUtil.urlBase64Decode(parts[1]);
    if (!decoded)
    {
      throw new Error('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }
}
