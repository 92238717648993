import {Model} from './model';
import {Student} from './student';
import {Teacher} from './teacher';
import {Time} from '@angular/common';
import {Subject} from './subject';
import {SchoolType} from './school-type';
import {TeacherInvoice} from './teacher-invoice';

export class Lesson extends Model {
	
	public student: Student;
	public teacher: Teacher;
	
	public subject: Subject;
	
	public schoolType: SchoolType;
	public schoolClass: number = 1;
	
	public lessonStart: number;
	public lessonEnd: number;
	
	public lessonComment: string = '';
	
	public state: string = '';
	
	public teacherCost: number;
	
	public teacherInvoice: TeacherInvoice;
	
	constructor()
	{
		super();
	}
}
