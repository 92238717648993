import {Injectable} from '@angular/core';
import {AuthService} from '../auth.service';
import {HttpClient} from '@angular/common/http';
import {SchoolType} from '../../_models/school-type';
import {RestService} from './rest-service';

@Injectable({
	providedIn: 'root'
})
export class SchoolTypeService extends RestService<SchoolType>
{
	
	constructor(httpClient: HttpClient, authService: AuthService)
	{
		super('school-types', httpClient, authService)
	}
	
}
