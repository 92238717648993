import {Component, OnInit} from '@angular/core';
import {Student} from '../_models/student';
import {CurrentTeacherService} from '../_services/current-teacher.service';
import {Filter} from '../_utils/filter/filter';
import {Operation} from '../_utils/filter/operation.enum';
import {StudentService} from '../_services/repositories/student.service';
import {Subject} from '../_models/subject';
import {HeaderService} from '../_services/header.service';
import {HeaderButton} from '../_utils/header-button.enum';
import {Teacher} from '../_models/teacher';

@Component({
	selector: 'app-my-students',
	templateUrl: './my-students.component.html',
	styleUrls: ['./my-students.component.scss']
})
export class MyStudentsComponent implements OnInit
{
	
	private students: Array<Student> = [];
	public studentsDisplay: Array<Student> = [];
	
	public searchTerm: string;
	
	constructor(private headerService: HeaderService,
				private studentService: StudentService,
				private currentTeacherService: CurrentTeacherService)
	{
		this.headerService.pageTitle = 'Meine Schüler';
		this.headerService.buttonAction = HeaderButton.MENU;
		
		
		let fltByTeacher = new Filter('teachers', currentTeacherService.teacher.id, Operation.IN);
		this.studentService.getAll([fltByTeacher]).subscribe((students: Array<Student>) =>
		{
			this.students = students;
			this.resetDisplay();
		});
	}
	
	public filterStudents(searchTerm : string) {
		this.searchTerm = searchTerm || '';
		if (this.searchTerm == '') {
			this.studentsDisplay = this.students.filter(() => {return true;})
		}
		this.studentsDisplay = this.students.filter((tbchecked : Student) => {
			return tbchecked.user.firstname.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0 ||
				tbchecked.user.lastname.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0
		});
	}
	
	private resetDisplay(): void
	{
		this.studentsDisplay = this.students.map((students: Student) =>
		{
			return students;
		});
	}
	
	public getSubjectsString(subjects: Array<Subject>): string
	{
		let values = subjects.map((subject: Subject) =>
		{
			return subject.name;
		});
		return values.join(', ');
	}
	
	ngOnInit()
	{
	}
	
}
