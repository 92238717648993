import {Injectable} from '@angular/core';
import {Teacher} from '../_models/teacher';
import {Subject} from '../_models/subject';

@Injectable({
	providedIn: 'root'
})
export class CurrentTeacherService
{
	
	private _teacher: Teacher = new Teacher();
	
	constructor()
	{
		
	}
	
	
	get teacher(): Teacher
	{
		return this._teacher;
	}
	
	set teacher(value: Teacher)
	{
		this._teacher = value;
	}
}
