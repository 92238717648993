import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NotImplementedComponent} from './not-implemented/not-implemented.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {TestComponentComponent} from './test-component/test-component.component';
import {RecordReadonlyComponent} from './test-component/record-readonly/record-readonly.component';
import {RecordEditComponent} from './test-component/record-edit/record-edit.component';
import {LoginComponent} from './login/login.component';
import {DefaultGuardService} from './_guards/default-guard.service';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SelectionListComponent} from './test-component/selection-list/selection-list.component';
import {ActionListComponent} from './test-component/action-list/action-list.component';
import {OptionListComponent} from './test-component/option-list/option-list.component';
import {MyLessonsComponent} from './my-lessons/my-lessons.component';
import {NoAccessComponent} from './no-access/no-access.component';
import {MyStudentsComponent} from './my-students/my-students.component';
import {MyStudentComponent} from './my-student/my-student.component';
import {NewLessonComponent} from './new-lesson/new-lesson.component';
import {MyTeacherInvoicesComponent} from './my-teacher-invoices/my-teacher-invoices.component';
import {MyTeacherInvoiceComponent} from './my-teacher-invoice/my-teacher-invoice.component';
import {MyAccountComponent} from './my-account/my-account.component';
import {MyAdditionalPositionsComponent} from './my-additional-positions/my-additional-positions.component';
import {MyAdditionalPositionComponent} from './my-additional-position/my-additional-position.component';

const routes: Routes = [
	/**
	 * Framework
	 */
	{
		path: '',
		component: DashboardComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
		
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'not-implemented',
		component: NotImplementedComponent
	},
	{
		path: 'no-access',
		component: NoAccessComponent
	},
	
	
	/**
	 * Application
	 */
	{
		path: 'lessons',
		component: MyLessonsComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
		
	},
	{
		path: 'lessons/:id',
		component: NewLessonComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
		
	},
	{
		path: 'students',
		component: MyStudentsComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
		
	},
	{
		path: 'students/:id',
		component: MyStudentComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	{
		path: 'invoices',
		component: MyTeacherInvoicesComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	{
		path: 'invoices/:id',
		component: MyTeacherInvoiceComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	{
		path: 'invoice-positions',
		component: MyAdditionalPositionsComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	{
		path: 'invoice-positions/:id',
		component: MyAdditionalPositionComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	{
		path: 'my-account',
		component: MyAccountComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	
	
	/**
	 * Sandbox
	 */
	{
		path: 'sandbox',
		component: TestComponentComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	{
		path: 'sandbox/record-readonly',
		component: RecordReadonlyComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	{
		path: 'sandbox/record-edit',
		component: RecordEditComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	{
		path: 'sandbox/selection-list',
		component: SelectionListComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	{
		path: 'sandbox/action-list',
		component: ActionListComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	{
		path: 'sandbox/option-list',
		component: OptionListComponent,
		canActivate: [DefaultGuardService],
		data: {roles: ['admin', 'teacher']}
	},
	{
		path: '**',
		component: NotFoundComponent
	},
	
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule
{
}
