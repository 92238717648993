import {Injectable} from '@angular/core';
import {User} from '../_models/user';
import {ServiceUtil} from '../_utils/service-util';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {IAuthTokenDecoded} from '../_interfaces/iauth-token-decoded';
import {ActivatedRoute, Router} from '@angular/router';
import {CurrentTeacherService} from './current-teacher.service';
import {Teacher} from '../_models/teacher';
import {async} from 'rxjs/internal/scheduler/async';

@Injectable({
	providedIn: 'root'
})
export class AuthService
{
	private _token: string;
	private tokenData: IAuthTokenDecoded;
	private _lastLoginFailed: boolean;
	private user: User;
	private roles: string[] = [];
	
	get lastLoginFailed(): boolean
	{
		return this._lastLoginFailed;
	}
	
	get token(): string
	{
		return this._token;
	}
	
	set token(value: string)
	{
		if (ServiceUtil.decodeJwt(value).exp > Date.now() / 1000)
		{
			this.extractUserData(value);
			this._token = value;
		}
	}
	
	constructor(private httpClient: HttpClient, private router: Router, private currentTeacherService: CurrentTeacherService)
	{
	}
	
	public login(username: string, password: string, targetUrl: string): void
	{
		this.httpClient.post<any>(`${environment.endpoint.url}users/login`, {
			password: password,
			username: username
		}).subscribe(
			(resp) =>
			{
				console.log(resp.token);
				if (resp.token)
				{
					this._token = resp.token;
					this._lastLoginFailed = false;
					localStorage.setItem('_token', this._token);
					this.extractUserData(this._token, targetUrl);
				}
				else
				{
					this._lastLoginFailed = true;
				}
			},
			() =>
			{
				this._lastLoginFailed = true;
			});
	}
	
	public logout(): void
	{
		this._token = null;
		this.tokenData = null;
		this.user = null;
		this.roles = new Array<string>();
		this._lastLoginFailed = false;
		localStorage.removeItem('_token');
		this.router.navigate(['/login']);
	}
	
	private extractUserData(token: string, targetUrl: string = '')
	{
		// this.syncLogin(token, targetUrl);
		
		let tokenData = ServiceUtil.decodeJwt(token);
		this.tokenData = tokenData;
		for (let i = 0; i < tokenData.data.userRoles.length; i++)
		{
			this.roles.push(tokenData.data.userRoles[i]);
		}
		let httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		});
		// this.httpClient.get<User>(`${environment.endpoint.url}users/${tokenData.data.userId}`, {headers: httpHeaders}).subscribe((value: User) =>
		this.httpClient.get<User>(`${environment.endpoint.url}users/own`, {headers: httpHeaders}).subscribe((value: User) =>
		{
			this.httpClient.get<Teacher>(`${environment.endpoint.url}teachers/own`, {headers: httpHeaders}).subscribe((teacher: Teacher) =>
			{
				this.currentTeacherService.teacher.user = this.user;
				
				this.user = value;
				if (!this.currentTeacherService.teacher)
				{
					this.currentTeacherService.teacher = new Teacher();
				}
				console.log('initial', teacher);
				if (teacher)
				{
					this.currentTeacherService.teacher = teacher;
					if (targetUrl != '')
						this.router.navigate([targetUrl]);
				}
				else
				{
					this.currentTeacherService.teacher.id = 4;
					this._lastLoginFailed = true;
				}
			});
		});
	}
	
	async syncLogin(token: string, targetUrl: string = '') {
		
		let tokenData = ServiceUtil.decodeJwt(token);
		this.tokenData = tokenData;
		for (let i = 0; i < tokenData.data.userRoles.length; i++)
		{
			this.roles.push(tokenData.data.userRoles[i]);
		}
		let httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		});
		
		await this.httpClient.get<User>(`${environment.endpoint.url}users/${tokenData.data.userId}`, {headers: httpHeaders}).subscribe((value: User) =>
		{
			this.user = value;
		});
		
		if (!this.currentTeacherService.teacher)
		{
			this.currentTeacherService.teacher = new Teacher();
		}
		
		this.currentTeacherService.teacher.user = this.user;
		
		await this.httpClient.get<Teacher>(`${environment.endpoint.url}teachers/own`, {headers: httpHeaders}).subscribe((teacher: Teacher) =>
		{
			console.log('initial', teacher);
			if (teacher)
			{
				this.currentTeacherService.teacher = teacher;
				if (targetUrl != '')
					this.router.navigate([targetUrl]);
			}
			else
			{
				this.currentTeacherService.teacher.id = 4;
				this._lastLoginFailed = true;
				// this.logout();
			}
		});
		
	}
	
	public isLoggedIn(): boolean
	{
		return this.tokenData != null && this.tokenData.exp > Date.now() / 1000;
	}
	
	public getLoggedInUser()
	{
		return this.user;
	}
	
	public hasRole(roles: any): boolean
	{
		let answer = false;
		
		roles.forEach((role) =>
		{
			this.roles.forEach((r) =>
			{
				if (r == role)
				{
					answer = true;
				}
			});
		});
		
		return answer;
	}
}
