import { Injectable } from '@angular/core';
import {AdditionalInvoicePosition} from '../../_models/additional-invoice-position';
import {RestService} from './rest-service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../auth.service';
import {Filter} from '../../_utils/filter/filter';
import {CurrentTeacherService} from '../current-teacher.service';
import {LessonState} from '../../_models/lesson-state.enum';

@Injectable({
  providedIn: 'root'
})
export class AdditionalInvoicePositionService extends RestService<AdditionalInvoicePosition>
{
	constructor(httpClient: HttpClient, authService: AuthService, private currentTeacherService: CurrentTeacherService)
	{
		super('additional-positions', httpClient, authService);
	}
	
	public getDateDisplayValue(date: Date)
	{
		
		if (!date.getDate)
		{
			date = new Date(date);
		}
		
		let dateDay = ('0' + date.getDate()).substr(-2);
		let dateMonth = ('0' + (date.getMonth() + 1)).substr(-2);
		let dateYear = date.getFullYear();
		
		return dateDay + '.' + dateMonth + '.' + dateYear;
	}
	
	async getAllMock(positionsFilter: Array<Filter>) {
		
		let positions: Array<AdditionalInvoicePosition> = [];
		
		let position1 = new AdditionalInvoicePosition();
		position1.id = 1;
		position1.teacher = this.currentTeacherService.teacher;
		position1.cost = 15.1;
		position1.date = new Date();
		position1.description = 'Fahrtkosten KW1';
		position1.state = LessonState.OPEN;
		
		positions.push(position1);
		positions.push(position1);
		positions.push(position1);
		
		return positions;
	}
}
