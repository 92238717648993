import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs/index';
import {AuthService} from '../_services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class DefaultGuardService implements CanActivate
{
	
	constructor(private authService: AuthService, private router: Router)
	{
	}
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
	{
		
		if (!this.authService.isLoggedIn())
		{
			this.router.navigate(['/login']);
			return false;
		}
		
		if (this.authService.hasRole(route.data.roles))
		{
			return true;
		}
		else
		{
			this.router.navigate(['/no-access']);
			return false;
		}
		
	}
}
