import {Injectable} from '@angular/core';
import {AuthService} from '../auth.service';
import {HttpClient} from '@angular/common/http';
import {Subject} from '../../_models/subject';
import {RestService} from './rest-service';

@Injectable({
	providedIn: 'root'
})
export class SubjectService extends RestService<Subject>
{
	constructor(httpClient: HttpClient, authService: AuthService)
	{
		super('subjects', httpClient, authService);
	}
}
