import { Component, OnInit } from '@angular/core';
import {HeaderService} from '../../_services/header.service';
import {HeaderButton} from '../../_utils/header-button.enum';

@Component({
  selector: 'app-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.scss']
})
export class SelectionListComponent implements OnInit {
	
	constructor(private headerService: HeaderService)
	{
		this.headerService.pageTitle = 'Selection List Example';
		this.headerService.buttonAction = HeaderButton.BACK;
	}

  ngOnInit() {
  }

}
