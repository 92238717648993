import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {AuthService} from './_services/auth.service';
import {HeaderService} from './_services/header.service';
import {HeaderButton} from './_utils/header-button.enum';
import {Location} from '@angular/common';
import {SubjectService} from './_services/repositories/subject.service';
import {Subject} from './_models/subject';
import {ResponsiveService} from './_services/responsive.service';
import {CurrentTeacherService} from './_services/current-teacher.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, AfterViewInit
{
	title = 'Tutoring Management';
	mobileQuery: MediaQueryList;
	
	public sideNavOpened: boolean;
	
	private readonly mobileQueryListener: () => void;
	
	constructor(
		// private changeDetectorRef: ChangeDetectorRef,
				media: MediaMatcher,
				private responsiveService: ResponsiveService,
				public authService: AuthService,
				public currentTeacherService: CurrentTeacherService,
				public  headerService: HeaderService,
				private subjectService: SubjectService,
				private location: Location)
	{
		
		this.headerService.pageTitle = 'LearnSolution';
		this.headerService.buttonAction = HeaderButton.MENU;
		
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this.mobileQueryListener = () =>
		{
			this.sideNavOpened = !this.mobileQuery.matches;
			// this.changeDetectorRef.detectChanges();
		};
		this.mobileQuery.addListener(this.mobileQueryListener);
		
		const tmpToken = localStorage.getItem('_token');
		if (tmpToken && tmpToken !== 'undefined')
		{
			this.authService.token = tmpToken;
		}
		
	}
	
	ngOnDestroy(): void
	{
		this.mobileQuery.removeListener(this.mobileQueryListener);
	}
	
	ngAfterViewInit(): void
	{
		this.mobileQueryListener();
	}
	
	toggleSideNav(): void
	{
		this.sideNavOpened = !this.sideNavOpened;
	}
	
	logout(): void
	{
		this.authService.logout();
	}
	
	navigateBack(): void
	{
		this.location.back();
	}
}
