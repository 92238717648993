import {Component, OnInit} from '@angular/core';
import {Lesson} from '../_models/lesson';
import {LessonService} from '../_services/repositories/lesson.service';
import {CurrentTeacherService} from '../_services/current-teacher.service';
import {Filter} from '../_utils/filter/filter';
import {Operation} from '../_utils/filter/operation.enum';
import {HeaderService} from '../_services/header.service';
import {HeaderButton} from '../_utils/header-button.enum';

@Component({
	selector: 'app-my-lessons',
	templateUrl: './my-lessons.component.html',
	styleUrls: ['./my-lessons.component.scss']
})
export class MyLessonsComponent implements OnInit
{
	private lessons: Array<Lesson> = [];
	public lessonsDisplay: Array<Lesson> = [];
	public searchTerm: string = '';
	
	constructor(private headerService: HeaderService, private lessonService: LessonService, private currentTeacherService: CurrentTeacherService)
	{
		
		this.headerService.pageTitle = 'Meine Nachhilfestunden';
		this.headerService.buttonAction = HeaderButton.MENU;
		
		let fltByTeacher = new Filter('teacher', currentTeacherService.teacher.id, Operation.EQUALS);
		this.lessonService.getAll([fltByTeacher]).subscribe((lessons: Array<Lesson>) =>
		{
			this.lessons = lessons;
			this.resetDisplay();
		});
	}
	
	private resetDisplay(): void
	{
		this.lessonsDisplay = this.lessons.map((lesson: Lesson) =>
		{
			return lesson;
		});
	}
	
	ngOnInit()
	{
	}
	
	public filterLessons(searchTerm: string)
	{
		this.searchTerm = searchTerm || '';
		if (this.searchTerm == '')
		{
			this.lessonsDisplay = this.lessons.filter(() =>
			{
				return true;
			});
		}
		this.lessonsDisplay = this.lessons.filter((tbchecked: Lesson) =>
		{
			return tbchecked.student.user.firstname.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0 ||
				tbchecked.student.user.lastname.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0;
		});
	}
	
	getLessonDateTimeString(start: number, end: number)
	{
		return this.lessonService.getLessonDateTimeString(start, end);
	}
	
	getStateDisplayValue(state: string)
	{
		return this.lessonService.getStateDisplayValue(state);
	}
	
}
