import {ChangeDetectorRef, Injectable} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';

@Injectable({
	providedIn: 'root'
})
export class ResponsiveService
{
	
	public mobileQuery: MediaQueryList;
	private readonly mobileQueryListener: () => void;
	
	constructor(
		// private changeDetectorRef: ChangeDetectorRef,
				private media: MediaMatcher)
	{
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this.mobileQueryListener = () =>
		{
			// this.changeDetectorRef.detectChanges();
		};
		this.mobileQuery.addListener(this.mobileQueryListener);
	}
	
	destroy()
	{
		this.mobileQuery.removeListener(this.mobileQueryListener);
	}
	
	init()
	{
		this.mobileQueryListener();
	}
}
