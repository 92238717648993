import {Component, OnInit} from '@angular/core';
import {HeaderService} from '../_services/header.service';
import {StudentService} from '../_services/repositories/student.service';
import {Student} from '../_models/student';
import {ActivatedRoute} from '@angular/router';
import {HeaderButton} from '../_utils/header-button.enum';
import {UserContact} from '../_models/user-contact';
import {User} from '../_models/user';
import {UserService} from '../_services/repositories/user.service';
import {Lesson} from '../_models/lesson';
import {LessonService} from '../_services/repositories/lesson.service';
import {Filter} from '../_utils/filter/filter';
import {CurrentTeacherService} from '../_services/current-teacher.service';
import {Gender} from '../_models/gender.enum';

@Component({
	selector: 'app-my-student',
	templateUrl: './my-student.component.html',
	styleUrls: ['./my-student.component.scss']
})
export class MyStudentComponent implements OnInit
{
	
	public student: Student = new Student();
	public userContacts: Array<UserContact> = [];
	public lessons: Array<Lesson> = [];
	
	constructor(private headerService: HeaderService,
				private studenService: StudentService,
				private lessonService: LessonService,
				private currentTeacherService: CurrentTeacherService,
				private route: ActivatedRoute,
				private userService: UserService)
	{
		this.headerService.pageTitle = 'Schüler';
		this.headerService.buttonAction = HeaderButton.BACK;
		
		this.student.subjects = [];
		
		let id = this.route.snapshot.params['id'];
		this.studenService.get(id).subscribe((student: Student) =>
		{
			this.student = student;
			
			this.userService.get(this.student.user.id).subscribe((user: User) =>
			{
				this.userContacts = user.userContacts;
			});
			
		});
		
		let filter = [
			new Filter('teacher', this.currentTeacherService.teacher.id),
			new Filter('student', id)
		];
		
		this.lessonService.getAll(filter).subscribe((lessons: Array<Lesson>) =>
		{
			this.lessons = lessons.map((lesson: Lesson) =>
			{
				return lesson;
			});
		});
	}
	
	getGenderDisplayName(gender: string)
	{
		return this.userService.getGenderDisplayName(gender);
	}
	
	getLessonDateTimeString(start: number, end: number)
	{
		return this.lessonService.getLessonDateTimeString(start, end);
	}
	
	getStateDisplayValue(state: string)
	{
		return this.lessonService.getStateDisplayValue(state);
	}
	
	ngOnInit()
	{
	}
	
}
