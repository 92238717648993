import {Component, OnInit} from '@angular/core';
import {HeaderService} from '../_services/header.service';
import {HeaderButton} from '../_utils/header-button.enum';
import {Lesson} from '../_models/lesson';
import {LessonService} from '../_services/repositories/lesson.service';
import {Filter} from '../_utils/filter/filter';
import {CurrentTeacherService} from '../_services/current-teacher.service';
import {LessonState} from '../_models/lesson-state.enum';
import {LinkType} from '../_utils/filter/link-type.enum';
import {Operation} from '../_utils/filter/operation.enum';
import {AdditionalInvoicePositionService} from '../_services/repositories/additional-invoice-position.service';
import {AdditionalInvoicePosition} from '../_models/additional-invoice-position';
import {TeacherInvoice} from '../_models/teacher-invoice';
import {Teacher} from '../_models/teacher';
import {TeacherInvoiceService} from '../_services/repositories/teacher-invoice.service';

@Component({
	selector: 'app-my-account',
	templateUrl: './my-account.component.html',
	styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit
{
	
	public lessons: Array<Lesson> = [];
	public positions: Array<AdditionalInvoicePosition> = [];
	public invoices: Array<TeacherInvoice> = [];
	public accountSum: number;
	public accountLessonSum: number = 0.0;
	public accountPositionSum: number = 0.0;
	
	constructor(private headerService: HeaderService,
				private currentTeacherService: CurrentTeacherService,
				private additionalPositionService: AdditionalInvoicePositionService,
				private invoiceService: TeacherInvoiceService,
				private lessonService: LessonService)
	{
		this.headerService.pageTitle = 'Konto';
		this.headerService.buttonAction = HeaderButton.MENU;
		
		let lessonFilters = [
			new Filter('teacher', this.currentTeacherService.teacher.id),
			new Filter('state', LessonState.OPEN),
			new Filter('state', LessonState.ACCOUNTING, Operation.EQUALS, LinkType.OR),
		];
		
		this.lessonService.getAll(lessonFilters).subscribe((lessons: Array<Lesson>) =>
		{
			this.lessons = lessons;
			this.calculateLessonSum();
		});
		
		let positionsFilter = [
			new Filter('teacher', this.currentTeacherService.teacher.id),
			new Filter('state', LessonState.OPEN),
			new Filter('state', LessonState.ACCOUNTING, Operation.EQUALS, LinkType.OR),
		];
		this.additionalPositionService.getAll(positionsFilter).subscribe((positions: Array<AdditionalInvoicePosition>) =>
		{
			this.positions = positions;
			this.calculatePositionSum();
		});
		
		let invoicesFilter = [
			new Filter('teacher', this.currentTeacherService.teacher.id),
			new Filter('status', LessonState.OPEN),
			new Filter('status', LessonState.ACCOUNTING, Operation.EQUALS, LinkType.OR),
		];
		this.invoiceService.getAll(invoicesFilter).subscribe((invoices: Array<TeacherInvoice>) =>
		{
			this.invoices = invoices;
		});
	}
	
	private calculateLessonSum()
	{
		this.accountLessonSum = 0.0;
		this.lessons.forEach((lesson: Lesson) =>
		{
			this.accountLessonSum += lesson.teacherCost;
		});
		this.calculateAccountSum();
	}
	
	private calculatePositionSum()
	{
		this.accountPositionSum = 0.0;
		this.positions.forEach((position: AdditionalInvoicePosition) =>
		{
			this.accountPositionSum += position.cost;
		});
		this.calculateAccountSum();
	}
	
	private calculateAccountSum()
	{
		this.accountSum = this.accountLessonSum + this.accountPositionSum;
	}
	
	ngOnInit()
	{
	}
	
	getTimespanDisplayValue(start: Date, end: Date) {
		return this.invoiceService.getTimespanDisplayValue(start, end);
	}
	
	getLessonDateTimeString(start: number, end: number)
	{
		return this.lessonService.getLessonDateTimeString(start, end);
	}
	
	getStateDisplayValue(state: string)
	{
		return this.lessonService.getStateDisplayValue(state);
	}
	
	getDateDisplayValue(date: Date)
	{
		return this.additionalPositionService.getDateDisplayValue(date);
	}
	
}
