import {Component, OnInit} from '@angular/core';
import {HeaderButton} from '../_utils/header-button.enum';
import {AdditionalInvoicePositionService} from '../_services/repositories/additional-invoice-position.service';
import {CurrentTeacherService} from '../_services/current-teacher.service';
import {HeaderService} from '../_services/header.service';
import {LessonService} from '../_services/repositories/lesson.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdditionalInvoicePosition} from '../_models/additional-invoice-position';
import {ActivatedRoute, Router, RouterLinkActive} from '@angular/router';
import {LessonState} from '../_models/lesson-state.enum';
import {MatSnackBar} from '@angular/material';

@Component({
	selector: 'app-my-additional-position',
	templateUrl: './my-additional-position.component.html',
	styleUrls: ['./my-additional-position.component.scss']
})
export class MyAdditionalPositionComponent implements OnInit
{
	
	public position: AdditionalInvoicePosition = new AdditionalInvoicePosition();
	public positionForm: FormGroup;
	
	public readonly: boolean = false;
	
	
	constructor(private headerService: HeaderService,
				private currentTeacherService: CurrentTeacherService,
				private lessonService: LessonService,
				private router: Router,
				private route: ActivatedRoute,
				private positionService: AdditionalInvoicePositionService,
				private snackBar: MatSnackBar)
	{
		this.headerService.pageTitle = 'Abrechnungsposition';
		this.headerService.buttonAction = HeaderButton.BACK;
		
		this.readonly = true;
		
		this.positionForm = new FormGroup({
			date: new FormControl('', [Validators.required]),
			description: new FormControl('', [Validators.required]),
			cost: new FormControl('', [Validators.required])
		});
		
		this.loadPosition();
	}
	
	private loadPosition()
	{
		let id = this.route.snapshot.params['id'];
		
		if (id == -1)
		{
			//New
			this.position.teacher = this.currentTeacherService.teacher;
			this.position.state = LessonState.OPEN;
			this.readonly = false;
		}
		else
		{
			//Edit
			this.positionService.get(id).subscribe((position: AdditionalInvoicePosition) =>
			{
				this.position = position;
				
				this.setDateToForm();
				
				if (this.position.state == LessonState.OPEN)
				{
					this.readonly = false;
				}
				
			});
			
		}
		
	}
	
	ngOnInit()
	{
	}
	
	public saveForm()
	{
		if (this.positionForm.valid)
		{
			try
			{
				this.setDateToPosition();
				if (this.route.snapshot.params['id'] != -1)
				{
					//Edit
					if (!this.position.teacherInvoice|| !this.position.teacherInvoice.id)
						this.position.teacherInvoice = null;
					this.positionService.update(this.position, this.position.id).subscribe(position =>
					{
						this.router.navigate(['/invoice-positions']);
						this.snackBar.open('Erfolgreich gespeichert', 'OK');
					});
					
				}
				else
				{
					//New
					this.positionService.create(this.position).subscribe(position =>
					{
						this.router.navigate(['/invoice-positions']);
						this.snackBar.open('Erfolgreich gespeichert', 'OK');
					});
				}
			}
			catch (e)
			{
				console.log(e);
			}
			
			
			
		}
		else
		{
			const invalid = [];
			const controls = this.positionForm.controls;
			for (const name in controls)
			{
				if (controls[name].invalid)
				{
					invalid.push('formErrors', name, controls[name].value);
				}
			}
			console.log(invalid);
			this.snackBar.open('Bitte behebe alle Fehler', 'OK');
		}
	}
	
	delete()
	{
		if (confirm('Willst du die Position wirklich löschen?'))
		{
			this.positionService.delete(this.position.id).subscribe((success) =>
			{
				this.router.navigate(['/invoice-positions']);
				this.snackBar.open('Erfolgreich gelöscht', 'OK');
			});
		}
	}
	
	private setDateToForm()
	{
		this.positionForm.get('date').setValue(this.position.date);
	}
	
	public setDateToPosition()
	{
		
		let dp = this.positionForm.get('date').value;
		
		if (!dp.getDate)
		{
			dp = new Date(this.positionForm.get('date').value);
		}
		
		this.position.date = new Date();
		
		this.position.date.setFullYear(dp.getFullYear());
		this.position.date.setMonth(dp.getMonth());
		this.position.date.setDate(dp.getDate());
		
	}
	
	getStateDisplayValue(state: string)
	{
		return this.lessonService.getStateDisplayValue(state);
	}
	
}
