import {LinkType} from './link-type.enum';
import {Operation} from './operation.enum';

export class Filter
{
  private _key: string;
  private _linkType: LinkType;
  private _operation: Operation;
  private _value: string | number;
  
  constructor(key: string, value: string | number, operation: Operation = Operation.EQUALS, linkType: LinkType = LinkType.AND)
  {
    this._key = key;
    this._linkType = linkType;
    this._operation = operation;
    this._value = value;
  }
  
  get key(): string
  {
    return this._key;
  }
  
  set key(value: string)
  {
    this._key = value;
  }
  
  get linkType(): LinkType
  {
    return this._linkType;
  }
  
  set linkType(value: LinkType)
  {
    this._linkType = value;
  }
  
  get operation(): Operation
  {
    return this._operation;
  }
  
  set operation(value: Operation)
  {
    this._operation = value;
  }
  
  get value(): string | number
  {
    return this._value;
  }
  
  set value(value: string | number)
  {
    this._value = value;
  }
  
  public toString(): string
  {
    return `${this.key}=${this.filterToString()}`;
  }
  
  public filterToString(): string
  {
    let value = this.value;
    return `[${this.linkType}^${this.operation}^${this.value}]`;
  }
}
