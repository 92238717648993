import {Component, OnInit} from '@angular/core';
import {HeaderService} from '../_services/header.service';
import {HeaderButton} from '../_utils/header-button.enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit
{
  
  constructor(private headerService: HeaderService)
  {
    this.headerService.buttonAction = HeaderButton.MENU;
    this.headerService.pageTitle = 'Dashboard';
    
  }
  
  ngOnInit()
  {
  }
  
}
