import {Model} from './model';
import {Subject} from './subject';
import {User} from './user';
import {SchoolType} from './school-type';

export class Student extends Model {
	public user: User;
	public subjects: Array<Subject> = [];
	
	public schoolType: SchoolType;
	public schoolClass: number;
	
	public school: string;
	
	public durationLessonUnit: number; //TODO
	
	public currentMarkAverage: number;
	
	public comment: string;
	
	constructor()
	{
		super();
	}
}
