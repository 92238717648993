import {Model} from './model';
import {TeacherInvoice} from './teacher-invoice';
import {Teacher} from './teacher';

export class AdditionalInvoicePosition extends Model {
	
	public teacher: Teacher;
	public date: Date;
	public description: string;
	public cost: number;
	public state: string;
	public teacherInvoice: TeacherInvoice;
	
	constructor()
	{
		super();
	}
}
