import {Model} from '../../_models/model';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Filter} from '../../_utils/filter/filter';
import {AuthService} from '../auth.service';

export abstract class RestService<T extends Model>
{
  /**
   * This should contain the API name of the endpoint
   */
  protected endpoint: string = '';
  protected url: string;
  
  constructor(endpoint: string, private httpClient: HttpClient, private authService: AuthService)
  {
    this.endpoint = endpoint;
    this.url = environment.endpoint.url + this.endpoint + '/';
  }
  
  protected getDefaultHeaders(): HttpHeaders
  {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authService.token}`
    });
  }
  
  create(record: T): Observable<T>
  {
    return this.httpClient.post<T>(this.url, record, {headers: this.getDefaultHeaders()});
  }
  
  get(id: number): Observable<T>
  {
    return this.httpClient.get<T>(`${this.url}${id}`, {headers: this.getDefaultHeaders()});
  }
  
  getAll(filters: Array<Filter> = []): Observable<Array<T>>
  {
    // Set query parameters
    let params = new HttpParams();
    for (let i = 0; i < filters.length; i++)
    {
      params = params.append(filters[i].key, filters[i].filterToString());
    }
    return this.httpClient.get<Array<T>>(this.url, {params: params, headers: this.getDefaultHeaders()});
  }
  
  update(record: T, id: number): Observable<T>
  {
    return this.httpClient.put<T>(`${this.url}${id}`, record, {headers: this.getDefaultHeaders()});
  }
  
  delete(id: number): Observable<any>
  {
    return this.httpClient.delete<any>(`${this.url}${id}`, {headers: this.getDefaultHeaders()});
  }
}
