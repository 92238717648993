import {Component, OnInit} from '@angular/core';
import {AuthService} from '../_services/auth.service';
import {HeaderService} from '../_services/header.service';
import {HeaderButton} from '../_utils/header-button.enum';
import {UserService} from '../_services/repositories/user.service';
import {Filter} from '../_utils/filter/filter';
import {User} from '../_models/user';
import {Operation} from '../_utils/filter/operation.enum';

@Component({
	selector: 'app-test-component',
	templateUrl: './test-component.component.html',
	styleUrls: ['./test-component.component.scss']
})
export class TestComponentComponent implements OnInit
{
	
	user: User;
	
	constructor(private authService: AuthService, private headerService: HeaderService, private userService: UserService)
	{
		this.headerService.pageTitle = 'Sandbox';
		this.headerService.buttonAction = HeaderButton.BACK;
		
	}
	
	ngOnInit()
	{
	}
	
	tstCreateUser()
	{
		let random = Math.floor(Math.random() * Math.floor(999999));
		
		this.user = new User();
		this.user.email = 'ola' + random  + '@web.de';
		this.user.username = 'wahnsinn' + random;
		this.user.password = 'ggg';
		this.user.firstname = 'Olga';
		this.user.lastname = 'Beschisskowa';
		
		this.userService.create(this.user).subscribe(
			(user: User) =>
			{
				console.log(user);
				this.user = user;
			}
		);
		
	}
	
	tstGetUser()
	{
		this.userService.get(1).subscribe(
			(user: User) =>
			{
				this.user = user;
				console.log('get', this.user);
			}
		);
	}
	
	tstGetUsersWithFilter()
	{
		let myFilter = new Filter('username', 'stu', Operation.STARTS_WITH);
		
		this.userService.getAll([myFilter]).subscribe(
			(users: Array<User>) =>
			{
				console.log('getAllWithFilter', users);
			}
		);
	}
	
	tstUpdateUser()
	{
		this.user.firstname = 'Sven2';
		this.userService.update(this.user, this.user.id).subscribe((user: User) =>
		{
			this.user = user;
			console.log('update', this.user);
		});
	}
	
	tstDeleteUser()
	{
		this.userService.delete(this.user.id).subscribe((success: boolean) =>
		{
			console.log('update', success);
		});
	}
}
