import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HeaderButton} from '../../_utils/header-button.enum';
import {HeaderService} from '../../_services/header.service';


@Component({
	selector: 'app-record-edit',
	templateUrl: './record-edit.component.html',
	styleUrls: ['./record-edit.component.scss'],
})
export class RecordEditComponent implements OnInit
{
	
	public recordForm: FormGroup;
	
	
	constructor(private headerService: HeaderService)
	{
		this.headerService.pageTitle = 'Nachhilfestunde hinzufügen';
		this.headerService.buttonAction = HeaderButton.BACK;
	}
	
	ngOnInit()
	{
		this.recordForm = new FormGroup({
			date: new FormControl('', [Validators.required]),
			start: new FormControl('', [Validators.required]),
			end: new FormControl('', [Validators.required]),
			student: new FormControl('', [Validators.required]),
			subject: new FormControl('', [Validators.required]),
			comment: new FormControl('', [Validators.required]),
		});
	}
	
}
