export enum Operation
{
	EQUALS = '=',
	LIKE = '*',
	STARTS_WITH = '%',
	ENDS_WITH = '$',
	GREATER_THAN = '>',
	LESS_THAN = '<',
	GREATER_EQUALS = '>=',
	LESS_EQUALS = '<=',
	BETWEEN = '<>',
	IN = 'IN'
}
